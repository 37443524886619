import React, { ReactNode, useCallback, useMemo } from 'react';

import classNames from 'classnames';
import { FieldErrors, FieldValues, Path, RegisterOptions, UseFormRegister } from 'react-hook-form';

import FormFieldTemplate from './template';
import { BaseComponentType } from '../../types';
import { useFormContext } from '../formContext';

export type UncontrolledFormFieldProps<T extends FieldValues> = {
  /* Name of the form field */
  name?: Path<T>;
  /* Label of the form field */
  label?: React.ReactNode;
  /* "register" object provided by invoking useForm */
  register?: UseFormRegister<T>;
  /* Error of the field */
  error?: FieldErrors<T>[keyof T];
  /* Input element that accepts register */
  children: ReactNode;
  /* Field validation rules*/
  rules?: RegisterOptions<T>;
  /* Hides the form field & spacings */
  hidden?: boolean;
  /* Hides the error list & spacings */
  hideErrorList?: boolean;
} & React.HTMLAttributes<HTMLDivElement> &
  BaseComponentType;

/**
 * A component for uncontrolled form fields.
 *
 * This component should not be used separately, use the FormField interface.
 * @example
 *
 *<FormField
 *  label="username"
 *  name="username"
 *  options={{
 *    required: "Please input your username!",
 *  }}
 *>
 *    <Input />
 *</FormField>
 */
const UncontrolledFormField = <T extends FieldValues>({
  label,
  name: nameFromField,
  register: registerFromProps,
  error: errorFromProps,
  children,
  rules: options,
  hidden,
  hideErrorList,
  testId,
  ...rest
}: UncontrolledFormFieldProps<T>) => {
  const form = useFormContext<T>();
  const register = registerFromProps ?? form.register;
  const error = errorFromProps ?? (nameFromField ? form.formState.errors[nameFromField] : undefined);

  const registerChildren = useCallback(
    (children: ReactNode, hasMultiple: boolean): ReactNode => {
      return React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          let newChildren: ReactNode = null;
          if (child.props.children) {
            hasMultiple = true;
            newChildren = registerChildren(child.props.children, hasMultiple);
          }

          const name = child.props.name || nameFromField;

          if (
            name === undefined ||
            !(
              child.type === 'input' ||
              child.type === 'select' ||
              typeof child.type === 'function' ||
              typeof child.type === 'object'
            )
          ) {
            // don't register without name
            // don't register elements that are not inputs, selects or custom components
            return React.cloneElement(child, child.props, newChildren);
          }

          const registerProps = register(name, options);

          return React.cloneElement(
            child,
            {
              id: hasMultiple ? undefined : nameFromField,
              ...registerProps,
              ...child.props,
              className: classNames(child.props.className, {
                'input-red': error?.message,
              }),
            },
            newChildren,
          );
        }
        return child;
      });
    },
    [error?.message, nameFromField, options, register],
  );

  const registeredChildren = registerChildren(children, false);

  let errorMessages = Object.values(error?.types || {}).map((errorMessage) => errorMessage);
  errorMessages = errorMessages.length > 0 ? errorMessages : [error?.message];

  return (
    <FormFieldTemplate
      name={nameFromField as string}
      label={label}
      errors={errorMessages}
      hidden={hidden}
      hideErrorList={hideErrorList}
      testId={testId ?? 'uncontrolled-form-field'}
      {...rest}
    >
      {registeredChildren}
    </FormFieldTemplate>
  );
};

export default UncontrolledFormField;
