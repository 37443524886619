import React from 'react';

import { Flex, Layout } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import HeadTags from '@/components/common/HeadTags';
import Header from '@Modules/market/market/components/Header';
import MarketContainer from '@Modules/market/market/MarketContainer';

const { Content } = Layout;

const Index = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeadTags title={t('navigation.market')} titleSuffix="subpage" />
      <Header />
      <Content>
        <Flex gap="4xl">
          <MarketContainer />
        </Flex>
      </Content>
    </>
  );
};

export default Index;
