import { withPublicRoute } from '@/libs/hocs';
import { usePersistedStore } from '@Store/persistedStore';
import UserQuery from '@Libs/clientInstances/userQuery';

import CookieModal from './CookieModal';

/**
 * Wrapper for cookie modal for anonymous users.
 *
 * Saves cookie consent to persisted store to be send when user logged in.
 */
const PublicCookieModal = () => {
  const { data } = UserQuery.useGetLegalDocuments({ type: 'cp' }, { suspense: false });

  const { acceptedCookies, setAcceptedCookies } = usePersistedStore();

  const onAccept = (acceptedValues: string[]) => {
    setAcceptedCookies({ values: acceptedValues, sent: false });
    return Promise.resolve();
  };

  return <CookieModal data={data?.[0]} onAccept={onAccept} open={acceptedCookies.values === undefined} />;
};

export default withPublicRoute(PublicCookieModal);
