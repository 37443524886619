import React from 'react';

import { Button, Flex, Icon, Layout, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useUserInfo from '@Libs/hooks/useUserInfo';
import { toLocaleCapitalize } from '@Libs/utils';

const { Hero } = Layout;

const Header = () => {
  const navigate = useNavigate();
  const { firstName } = useUserInfo();
  const { t, i18n } = useTranslation();

  return (
    <>
      <Hero view="strip">
        <Flex
          wrap={{ xs: 'wrap', sm: 'nowrap' }}
          minWidth="min"
          items={{ md: 'center' }}
          gap="lg"
          justify={{ sm: 'start', md: 'end' }}
          direction={{ xs: 'col', md: 'row' }}
        >
          <Flex.Item flexType="auto">
            <Text heading size="xl" color="white" weight="bold" my="md">
              {t('assets.diversify-assets-message', { name: toLocaleCapitalize(i18n.language, firstName) })}
            </Text>
          </Flex.Item>

          <Flex.Item flex gap="md" direction="row" items="center" justify={{ sm: 'start', lg: 'end' }}>
            <Button
              flexType="1"
              startIcon={<Icon type="o:qr-code" />}
              variant="outline-filled"
              size="lg"
              onClick={() => navigate('/crypto')}
            >
              {t('common.deposit')}
            </Button>
            <Button
              flexType="1"
              startIcon={<Icon type="o:paper-airplane" />}
              variant="outline-filled"
              size="lg"
              onClick={() => navigate('/crypto/withdraw')}
            >
              {t('common.send')}
            </Button>
          </Flex.Item>
        </Flex>
      </Hero>
    </>
  );
};

export default Header;
