import { BlogQuery } from '@bilira-org/react-utils';

import { env } from '@Libs/constants/env';

import getI18nLanguage from '../helpers/getI18nLanguage';

const client = new BlogQuery({
  baseUrl: env.CONTENT_API_BASE_URL,
  getLocale: getI18nLanguage,
});

Object.freeze(client);

export default client;
