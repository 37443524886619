import React, { FC, memo } from 'react';

import { Block, Flex, Icon, List, Loop, Meta, Skeleton, Text, withSuspense } from '@bilira-org/design';
import type { CryptoNetworkType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import CDNQuery from '@Libs/clientInstances/cdnQuery';
import CryptoQuery from '@Libs/clientInstances/cryptoQuery';

import NetworkInfo from './NetworkInfo';

interface INetwork {
  onFinish: () => void;
  setNetwork: (network: CryptoNetworkType) => void;
  filter?: string[];
  networks?: CryptoNetworkType[];
  isPending?: boolean;
  activeAsset?: string;
}

const Network: FC<INetwork> = ({ networks, isPending, onFinish, setNetwork, activeAsset }) => {
  const { t } = useTranslation();
  const { data } = CryptoQuery.useGetNetworks();
  const { data: withdrawalFees, isLoading: withdrawalFeesLoading } = CryptoQuery.useGetWithdrawalFees({
    suspense: false,
    enabled: !!activeAsset,
  });
  const { data: networkETA } = CDNQuery.useGetNetworkETA({ suspense: false });

  return (
    <List
      testId="network-list"
      color="neutral-300"
      space="sm"
      dataSource={networks ?? data}
      loading={isPending}
      skeleton={
        <Block row justify="between" items="center" p="md" gap="lg">
          <Block row items="center" gap="md">
            <Skeleton width="size-9" height="size-9" rounded="full" />

            <Block gap="md">
              <Skeleton height="size-3" width="size-28" />

              <Skeleton height="size-3" width="size-14" />
            </Block>
          </Block>

          <Skeleton height="size-3" width="size-9" />
        </Block>
      }
      skeletonLine={3}
      renderItem={(item) => (
        <List.Item
          size={{ xs: 'sm', sm: 'md' }}
          key={item.name}
          hoverBgColor="theme-n200d800"
          onClick={() => {
            setNetwork(item);
            onFinish();
          }}
        >
          <Meta
            space="sm"
            title={
              <Text size={{ xs: 'sm', sm: 'sm', md: 'base' }} color="secondary-500" weight="semibold">
                {item.name}
              </Text>
            }
            description={
              <Flex>
                {activeAsset && (
                  <NetworkInfo
                    network={item.alias}
                    activeAsset={activeAsset}
                    fees={withdrawalFees}
                    feesLoading={withdrawalFeesLoading}
                  />
                )}
                <Text color="neutral-600" size="xs">
                  {t('crypto.select-network-modal.eta-minutes', {
                    minutes: networkETA?.[item.alias] ?? '-',
                  })}
                </Text>
              </Flex>
            }
          />
          <Icon type="s:chevron-right" size="lg" color="neutral-600" />
        </List.Item>
      )}
    />
  );
};

export default withSuspense(
  memo(Network),
  <Loop data={3}>
    <Block row justify="between" items="center" p="md" gap="lg">
      <Block row items="center" gap="md">
        <Skeleton width="size-9" height="size-9" rounded="full" />

        <Block gap="md">
          <Skeleton height="size-3" width="size-28" />

          <Skeleton height="size-3" width="size-14" />
        </Block>
      </Block>

      <Skeleton height="size-3" width="size-9" />
    </Block>
  </Loop>,
);
