import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import routeMap, { routesAvailableLocale } from '@Libs/route/routeMap';

/**
 * Calculates the locale-specific route based on the current pathname
 * and the current language from the i18n translation hook.
 *
 * @returns  An object with the following properties:
 *   - route: The locale-specific route based on the current pathname.
 *   - getLocaleRoute: A function to get the locale-specific route for a given path.
 */
const useLocaleRoute = () => {
  const { pathname } = useLocation();
  const { i18n } = useTranslation();

  /**
   * Retrieves the localized route based on the provided path.
   * If a localized route is not found, the original path is returned.
   *
   * @param {string} path - The path for which to get the localized route.
   * @returns {string} - The localized route or the original path if not found.
   */
  const getLocaleRoute = (path: string): string => {
    const foundRoute = routesAvailableLocale.find((route) => Object.values(route).includes(path));
    return foundRoute?.[i18n.language as 'tr' | 'en'] || path;
  };

  /**
   * Retrieves the route based on the provided key.
   *
   * @param {keyof typeof routeMap} key - The key to use for retrieving the route.
   * @returns {string} - The route corresponding to the key.
   */
  const getRoute = useCallback(
    (key: keyof typeof routeMap): string => {
      return routeMap[key][i18n.language as 'tr' | 'en'];
    },
    [i18n.language],
  );

  return { route: getLocaleRoute(pathname), getLocaleRoute, getRoute };
};

export default useLocaleRoute;
