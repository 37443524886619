import React from 'react';

import { Block, Breadcrumbs, Button, Flex, Hidden, Icon, Layout, Link, Navbar, Span, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import useLocaleRoute from '@Libs/hooks/useLocaleRoute';

import { RawSwapButton } from './components/SwapButton';
import { useGetNavbarData } from './hooks/useGetNavbarData';

const { Aside } = Layout;

const Sidebar = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { getRoute } = useLocaleRoute();
  const { data, mobileData } = useGetNavbarData();

  return (
    <Aside responsive>
      <Hidden show={{ sm: true }}>
        <Navbar id="navbar" data={data.filter((d) => d.display)} defaultValue={location.pathname + location.hash} />
      </Hidden>
      <Hidden show={{ xs: true }}>
        {location.pathname.includes(getRoute('Market')) && (
          <Block p="lg">
            <RawSwapButton stretch />
          </Block>
        )}
        <Navbar id="navbar-mobile" data={mobileData.filter((d) => d.display)} defaultValue={location.pathname} />
      </Hidden>
      <Hidden show={{ xl: true }}>
        <Flex gap="lg">
          <Link width="size-full" anchor href={t('urls.download')} target="_blank">
            <Button variant="outline" size="xs" stretch>
              <Flex direction="row" justify="between" width="full" p="xs" items="center">
                <Text color="neutral-900" size="sm">
                  {t('common.download')}
                </Text>
                <Flex direction="row" items="center" gap="lg">
                  <Icon type="s:app-store" color="neutral-700" size="sm" />
                  <Icon type="s:play-store" color="neutral-700" size="sm" />
                </Flex>
              </Flex>
            </Button>
          </Link>
          <Breadcrumbs listType="disc" justify="center">
            <Breadcrumbs.Item href={t('urls.terms-of-usage')} target="_blank">
              <Span color="neutral-700" size="xs">
                {t('navigation.terms-of-usage')}
              </Span>
            </Breadcrumbs.Item>
            <Breadcrumbs.Item href={t('urls.audit-reports')} target="_blank">
              <Span color="neutral-700" size="xs">
                {t('navigation.audit-reports')}
              </Span>
            </Breadcrumbs.Item>
          </Breadcrumbs>
        </Flex>
      </Hidden>
    </Aside>
  );
};

export default Sidebar;
