import React, { useCallback, useEffect, useState } from 'react';

import {
  AllTransactionsRecordType,
  TransactionDirectionType,
  TransactionsParamsType,
  TransactionTypes,
} from '@bilira-org/react-utils';
import { useNavigate } from 'react-router-dom';

import { TransactionRecordInfo } from '@/libs/hooks/useGetTransactionDetail';
import useGetTransactions from '@Libs/hooks/useGetTransactions';
import useAuth from '@Libs/hooks/userAuth';

import { initialFiltersState } from '../data';

export type TransactionStatusType = { type?: TransactionTypes; direction?: TransactionDirectionType };

const useTransactionTable = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Omit<TransactionsParamsType, 'account'>>(
    initialFiltersState['deposit-withdraw'],
  );
  const [modalActive, setModalActive] = useState(false);
  const { account } = useAuth();
  const [selectedTransactionRecordInfo, setSelectedTransactionRecordInfo] = useState<TransactionRecordInfo>();

  const [type, setType] = useState<TransactionTypes>('fiat');

  const {
    data: { records: data = [], _metadata: metadata = undefined } = {},
    isPending,
    refetch,
  } = useGetTransactions({
    type: type,
    params: {
      account: account,
      ...filters,
    },
  });

  const tableCallback = useCallback((item: AllTransactionsRecordType) => {
    const customActions: Record<string, () => void> = {
      'other:payback': () => navigate('/referral'),
    };

    const defaultAction = () => {
      setSelectedTransactionRecordInfo({ id: item.id, type: item.type });
      setModalActive(true);
    };

    const actionKey = `${item.direction}:${item.type}`;
    const selectedAction = customActions[actionKey] || defaultAction;

    selectedAction();
  }, []);

  const onFilterChange = useCallback((value: typeof filters) => {
    setFilters({ ...value });
  }, []);

  return {
    filters,
    setFilters: onFilterChange,
    type,
    setType,
    modalActive,
    setModalActive,
    tableCallback,
    selectedTransactionRecordInfo,
    data: data as AllTransactionsRecordType[],
    metadata,
    isPending,
    refetch,
  };
};

export default useTransactionTable;
