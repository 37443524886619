import { TableSortingState } from '@bilira-org/design/src/components/table/dataTable';
import { AssetListParamType, SortOrderType } from '@bilira-org/react-utils';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface StorageValues {
  MARKET_TAB: number;
  MARKET_TABLE_SORT?: { field: AssetListParamType['sort']; direction: SortOrderType; original?: TableSortingState };
}

interface GeneralLocalStorage {
  localStore: StorageValues;
  setLocalStore: (data: Partial<StorageValues>) => void;
}

export const useLocalStorage = create<GeneralLocalStorage>()(
  persist(
    (set) => ({
      localStore: {
        MARKET_TABLE_SORT: { field: 'MarketCap', direction: 'desc', original: [{ id: 'MarketCap', desc: true }] },
      } as StorageValues,
      setLocalStore: (data) => set((state) => ({ localStore: { ...state.localStore, ...data } })),
    }),
    {
      name: 'general-local-storage',
    },
  ),
);
