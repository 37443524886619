import { Badge, Block, Flex, Icon, Panel, Separator, Span, Text, Tooltip, useCopy } from '@bilira-org/design';
import { ReferralCodeType, truncateMiddle } from '@bilira-org/react-utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import FormattedNumber from '@/components/common/FormattedNumber';
import FormattedPercentage from '@/components/common/FormattedPercentage';
import { getReferralRegisterUrl } from '@/libs/utils';
import DisplayFeatureFlag from '@Components/common/DisplayFeatureFlag';

import UpdateReferralButton from '../createReferral/UpdateReferralButton';
import { calculateCashbackPercentage } from '../helpers';

type Props = {
  referralCode: ReferralCodeType;
  index: number;
  defaultCommission: number;
  refetch: () => void;
};

const ReferralItem = ({ referralCode, index, defaultCommission, refetch }: Props) => {
  const { t, i18n } = useTranslation();
  const { onCopy } = useCopy({ copiedNotification: t('common.copied') });

  const commissionPercentage = referralCode.value * 100;
  const cashbackPercentage = calculateCashbackPercentage(defaultCommission, referralCode.value);
  const createdAt = dayjs(referralCode.created_at).locale(i18n.language).format('DD MMM YYYY, HH:mm');
  const referralRegisterUrl = getReferralRegisterUrl(referralCode.code);
  const truncatedReferralRegisterUrl = truncateMiddle(referralRegisterUrl, 22, referralCode.code.length);

  return (
    <Panel color="theme-wn" border="neutral-400" size="none" key={referralCode.code}>
      <Flex direction="row" gap="lg" p="xl">
        <Flex.Item flexType="none" grow="0">
          <Text size="4xl" weight="bold" color="neutral-400">
            {index + 1}
          </Text>
        </Flex.Item>
        <Flex.Item grow="1">
          <Flex direction={{ xs: 'col', sm: 'col', lg: 'row' }} gap="lg" items={{ lg: 'end' }}>
            <Flex.Item grow="1" flex justify="start">
              <Block row justify="start" items="center" gap="sm" mb="lg">
                <Text size="xl" weight="medium" color="secondary-500">
                  {referralCode.title}
                </Text>
                <UpdateReferralButton referralCode={referralCode} refetch={refetch} />
                {referralCode.default && (
                  <Badge size="md" color="green" variant="square" bordered transparent>
                    <Span color="green-500">{t('common.standard')}</Span>
                  </Badge>
                )}
              </Block>
              <Flex.Item grow="1" gap="sm" flex direction="row" items="center">
                <Text size="sm" color="neutral-700" onClick={() => onCopy({ copyValue: referralCode.code })}>
                  {t('referral.referral-code')}: {referralCode.code}
                </Text>
                <Icon
                  type="o:square-2-stack"
                  size="sm"
                  color="neutral-700"
                  onClick={() => onCopy({ copyValue: referralCode.code })}
                />
              </Flex.Item>
            </Flex.Item>
            <Flex.Item grow="1" gap="sm" flex direction="row" items="center">
              <Text size="sm" color="neutral-700" onClick={() => onCopy({ copyValue: referralRegisterUrl })}>
                {t('referral.referral-url')}: {truncatedReferralRegisterUrl}
              </Text>
              <Icon
                type="o:square-2-stack"
                size="sm"
                color="neutral-700"
                onClick={() => onCopy({ copyValue: referralRegisterUrl })}
              />
            </Flex.Item>
            <Flex.Item grow="1">
              <Text size="sm" color="neutral-700">
                {t('common.creation-date')}: {createdAt}
              </Text>
            </Flex.Item>
          </Flex>
        </Flex.Item>
      </Flex>
      <Separator width="size-full" />
      <Flex direction="row" gap="lg" p="xl">
        <Flex.Item flexType="none" grow="0">
          <Text size="4xl" weight="bold" color="neutral-300">
            &nbsp;&nbsp;&nbsp;
          </Text>
        </Flex.Item>
        <Flex.Item grow="1">
          <Flex direction={{ xs: 'col', sm: 'col', lg: 'row' }} gap="lg" items={{ lg: 'end' }}>
            <DisplayFeatureFlag rcKey="REFERRAL_EARN">
              <Flex.Item grow="1">
                <Text size="sm" color="neutral-700">
                  {t('referral.my-commission-percentage')}
                </Text>
                <Text size="xl" weight="medium" color="secondary-500">
                  <FormattedPercentage value={commissionPercentage} />
                </Text>
              </Flex.Item>
              <Flex.Item grow="1">
                <Text size="sm" color="neutral-700">
                  {t('referral.referral-cashback-rate')}
                </Text>
                <Text size="xl" weight="medium" color="secondary-500">
                  <FormattedPercentage value={cashbackPercentage} />
                </Text>
              </Flex.Item>
            </DisplayFeatureFlag>
            <Flex.Item grow="1">
              <Text size="sm" color="neutral-700">
                {t('referral.successful-invites')}
              </Text>
              <Text size="xl" weight="medium" color="secondary-500">
                {referralCode.referral_count || 0}
              </Text>
            </Flex.Item>
            <DisplayFeatureFlag rcKey="REFERRAL_EARN">
              <Flex.Item grow="1">
                <Flex direction="row" items="center" gap="xs">
                  <Tooltip
                    width="size-max"
                    placement="top"
                    message={t('referral.total-earning-info', { rate: commissionPercentage })}
                  >
                    <Icon color="neutral-700" type="o:question-mark-circle" size="sm" />
                  </Tooltip>
                  <Text size="sm" color="neutral-700">
                    {t('referral.total-earning')}
                  </Text>
                </Flex>
                <FormattedNumber
                  size="xl"
                  weight="medium"
                  color="secondary-500"
                  value={referralCode.earning.toString(10)}
                  suffix="TRYB"
                />
              </Flex.Item>
            </DisplayFeatureFlag>
          </Flex>
        </Flex.Item>
      </Flex>
    </Panel>
  );
};

export default ReferralItem;
