import { Text } from '@bilira-org/design';
import {
  calculateRewardPoints,
  ForceDecimal,
  RewardTaskState,
  shouldDisplayRewardMultiplier,
} from '@bilira-org/react-utils';

import FormattedNumber from '@Components/common/FormattedNumber';

import { RewardTaskProps } from '../RewardTask';

interface TaskPointsProps {
  task: RewardTaskProps['task'];
  taskState: RewardTaskState;
}

function RewardTaskPoints({ task, taskState }: TaskPointsProps) {
  if (shouldDisplayRewardMultiplier(task)) {
    return (
      <Text weight="medium" color="yellow-900">
        <FormattedNumber
          as="span"
          weight="medium"
          color="yellow-900"
          value={task.point_multiplier}
          formatPriceProps={{ precision: undefined, decimal: undefined, forceDecimal: undefined, defaultValue: '-' }}
        />
        x
      </Text>
    );
  }

  return (
    <FormattedNumber
      weight="medium"
      color="yellow-900"
      value={calculateRewardPoints(task, taskState)}
      formatPriceProps={{ decimal: 2, forceDecimal: ForceDecimal.STRICT_DECIMAL, defaultValue: '-' }}
    />
  );
}

export default RewardTaskPoints;
