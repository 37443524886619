import React, { useMemo } from 'react';

import {
  Block,
  ColumnDef,
  DataTable,
  Flex,
  Meta,
  Overflow,
  Skeleton,
  TableBodyStyle,
  TableHeaderStyle,
  Text,
} from '@bilira-org/design';
import { FeeDataType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { useSessionStore } from '@/store';
import IconCoin from '@Components/icon/IconCoin';
import cdnQuery from '@Libs/clientInstances/cdnQuery';
import cryptoQuery from '@Libs/clientInstances/cryptoQuery';
import MinDepositLimit from '@Modules/settings/commission/fee/components/MinDepositLimit';

import EmptyText from './EmptyText';
import Fee from './Fee';
import MinTransaction from './MinTransaction';

const headerStyle: TableHeaderStyle = { showBackground: true, px: '2xl', py: 'sm' };
const bodyStyle: TableBodyStyle = { px: '2xl', py: 'lg' };

const DepositWithdraw = () => {
  const { t } = useTranslation();
  const isLoggedIn = useSessionStore((state) => state.isLoggedIn);

  const { data: withdrawalFees, isLoading: withdrawalFeesLoading } = cryptoQuery.useGetWithdrawalFees({
    suspense: false,
  });

  const { data, isLoading } = cdnQuery.useGetFeeData({
    suspense: false,
  });

  const columns = useMemo<ColumnDef<FeeDataType>[]>(
    () => [
      {
        header: 'Coin/Token',
        accessorKey: 'coin',
        skeleton: (
          <Flex direction="row" gap="sm">
            <Skeleton rounded="full" width="size-6" />
            <Skeleton width="size-24" />
          </Flex>
        ),
        cell: (entry) => {
          return (
            <Meta
              space="sm"
              extra={<IconCoin size="2xl" type={entry.row.original.coin} />}
              title={
                <Text color="secondary-500" size="sm" weight="medium">
                  {entry.row.original.coin === 'TRY' ? t('commission.bank-transfer') : entry.row.original.coin}
                </Text>
              }
            />
          );
        },
      },
      {
        header: t('commission.network'),
        accessorKey: 'network',
        skeleton: <Skeleton width="size-24" />,
        cell: ({ row: { original: entry } }) => {
          if (!entry.network) {
            return <EmptyText />;
          }
          return (
            <Flex gap="xs" items={{ xs: 'end' }}>
              {entry.network.map((item) => (
                <Text key={item.alias} size="sm" weight="regular">
                  {item.name}
                </Text>
              ))}
            </Flex>
          );
        },
      },
      {
        header: t('commission.min-deposit'),
        accessorKey: 'network',
        id: 'min-deposit',
        skeleton: <Skeleton width="size-24" />,
        show: isLoggedIn,
        cell: ({ row: { original: entry } }) => {
          if (entry.coin === 'TRY' || !entry.network) {
            return <EmptyText />;
          }
          return (
            <Flex gap="xs" items={{ xs: 'end' }}>
              <MinDepositLimit asset={entry.coin} networks={entry.network} />
            </Flex>
          );
        },
      },
      {
        header: t('commission.deposit-fee'),
        id: 'deposit-fee',
        skeleton: <Skeleton width="size-24" />,
        cell: () => {
          return <Text size="sm">{t('common.free')}</Text>;
        },
      },
      {
        header: t('commission.min-withdraw'),
        accessorKey: 'network',
        skeleton: <Skeleton width="size-24" />,
        id: 'min-withdraw',
        show: isLoggedIn,
        cell: ({ row: { original: entry } }) => {
          return (
            <Flex gap="xs" items={{ xs: 'end' }}>
              {entry.coin !== 'TRY' ? (
                entry.network?.map((item) => (
                  <MinTransaction
                    domain="crypto"
                    key={item.alias}
                    asset={entry.coin}
                    network={item.alias}
                    direction="withdrawal"
                  />
                ))
              ) : (
                <MinTransaction domain="fiat" direction="withdrawal" />
              )}
            </Flex>
          );
        },
      },
      {
        header: t('commission.withdraw-fee'),
        accessorKey: 'network',
        skeleton: <Skeleton width="size-24" />,
        id: 'withdraw-fee',
        cell: ({ row: { original: entry } }) => {
          return (
            <Flex gap="xs" items={{ xs: 'end' }}>
              {entry.network ? (
                entry.network.map((item) => (
                  <Fee
                    key={item.alias}
                    symbol={entry.coin}
                    network={item.alias}
                    fees={withdrawalFees}
                    loading={withdrawalFeesLoading}
                  />
                ))
              ) : entry.coin === 'TRY' ? (
                <Fee symbol="TRY" />
              ) : (
                <EmptyText />
              )}
            </Flex>
          );
        },
      },
    ],
    [isLoggedIn, t, withdrawalFees, withdrawalFeesLoading],
  );

  return (
    <>
      <Block my="sm">
        <Text size="xs" color="neutral-700">
          *{t('commission.warnings.no-fee-for-deposit-transactions')}
        </Text>
        <Text size="xs" color="neutral-700">
          *{t('commission.warnings.fixed-fee-for-crypto-withdrawals')}
        </Text>
        <Text size="xs" color="neutral-700">
          *{t('commission.warnings.withdraw-fee-based-on-network')}
        </Text>
        <Text size="xs" color="neutral-700">
          *{t('commission.warnings.withdraw-fees-can-dynamically-change')}
        </Text>
      </Block>
      <Overflow overflow="x-auto">
        <DataTable.Responsive
          headerStyle={headerStyle}
          bodyStyle={bodyStyle}
          emptyMessage={t('otc.table.empty-info')}
          divider
          data={data || []}
          columns={columns}
          loading={isLoading}
          skeletonRowCount={5}
        />
      </Overflow>
    </>
  );
};

export default DepositWithdraw;
