import { Block, Button, DateRangePicker, Display, Dropdown, Label } from '@bilira-org/design';
import { CustomWidthType } from '@bilira-org/design/types';
import { LanguageUniversalType, TransactionsParamsType, TransactionTypes } from '@bilira-org/react-utils';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { subtractMonths } from '@Libs/utils';

import AssetFilter from './AssetFilter';
import { transactionDateData, transactionStatusData, transactionTypeData } from '../data';

type FiltersProps = {
  filters: Omit<TransactionsParamsType, 'account'>;
  setFilters: (filters: Omit<TransactionsParamsType, 'account'>) => void;
  type: TransactionTypes;
  setType: (type: TransactionTypes) => void;
  displayVariant?: 'table' | 'export';
  filterLabels: Record<string, string | undefined>;
  onReset?: () => void;
  customDate?: [Dayjs, Dayjs];
  setCustomDate: (value?: [Dayjs, Dayjs]) => void;
  getDropdownSize: (width: CustomWidthType) => CustomWidthType;
  updateFilterKey: (key: string, value?: string) => void;
};

const OtherFilters = ({
  displayVariant,
  filters,
  type,
  setFilters,
  setType,
  filterLabels,
  onReset,
  customDate,
  setCustomDate,
  getDropdownSize,
  updateFilterKey,
}: FiltersProps) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div>
        <Label>{t('transactions.type')}</Label>
        <Dropdown
          width={getDropdownSize('140')}
          listSize={getDropdownSize('140')}
          justify="between"
          position="left"
          value={t(`transactions.${type}`)}
          size="lg"
          onSelect={(item) => {
            setType(item.data);
            setFilters({ ...filters, asset: undefined });
          }}
          dataSource={transactionTypeData['other']}
          renderItem={(item) => (
            <Dropdown.Item selected={item.data === type} key={item.name}>
              {t(item.name)}
            </Dropdown.Item>
          )}
        />
      </div>

      <div>
        <Label>{t('transactions.date')}</Label>
        <DateRangePicker
          placeholder={t('common.all')}
          locale={i18n.language as LanguageUniversalType}
          value={customDate}
          onChange={(dates) => {
            setCustomDate(dates);
            setFilters({ ...filters, startDate: dates[0]?.toISOString(), endDate: dates[1]?.toISOString() });
            updateFilterKey('date', undefined);
          }}
          label={filterLabels.date ? t(filterLabels.date) : undefined}
          datePickerLabel={t('common.custom-date-range')}
          width={getDropdownSize('140')}
          limit={[90, 'days']}
        >
          {transactionDateData.map((item) => (
            <Dropdown.Item
              key={item.name}
              onClick={() => {
                const date = item.key === 0 ? undefined : subtractMonths(new Date(), item.key).toISOString();
                setFilters({ ...filters, startDate: date, endDate: undefined });
                setCustomDate(undefined);
                updateFilterKey('date', item.name);
              }}
              selected={item.name === filterLabels.date}
            >
              {t(item.name)}
            </Dropdown.Item>
          ))}
        </DateRangePicker>
      </div>
      <div>
        <Label>{t('transactions.crypto')}</Label>
        <AssetFilter
          disabled={type === 'fiat'}
          value={filters.asset}
          onSelect={(asset) => setFilters({ ...filters, asset: asset.symbol })}
          fluid={displayVariant === 'export'}
        />
      </div>
      <Display show={displayVariant !== 'export'}>
        <div>
          <Label>{t('transactions.status')}</Label>
          <Dropdown
            textColor="neutral-500"
            color="neutral-200"
            width={getDropdownSize('140')}
            listSize={getDropdownSize('140')}
            justify="between"
            position="right"
            disabled={true}
            value={t('transactions.statuses.completed')}
            size="lg"
            dataSource={transactionStatusData}
            onSelect={(item) => {
              setFilters({ ...filters, status: item.key } as Omit<TransactionsParamsType, 'account'>);
            }}
            renderItem={(item) => (
              <Dropdown.Item key={item.name} selected={item.key === filters.status}>
                {t(`transactions.statuses.${item.name}`)}
              </Dropdown.Item>
            )}
          />
        </div>

        <Block>
          <Label>&nbsp;</Label>
          <Block row items="center" height="size-full">
            <Button size="sm" variant="outline" onClick={onReset} style={{ margin: '1px' }}>
              {t('common.reset')}
            </Button>
          </Block>
        </Block>
      </Display>
    </>
  );
};

export default OtherFilters;
