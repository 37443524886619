import React, { memo, useMemo } from 'react';

import { Block } from '@bilira-org/design';
import { sortRewardTasks } from '@bilira-org/react-utils';

import RewardsQuery from '@Libs/clientInstances/rewardsQuery';

import RewardTask from '../../components/RewardTask';

const TaskList = () => {
  const { data } = RewardsQuery.useGetUserParticipation();

  const sortedTasks = useMemo(() => sortRewardTasks(data?.tasks), [data?.tasks]);

  if (!data) {
    return null;
  }

  return (
    <>
      <Block gap="lg" mb="lg">
        {sortedTasks.map((task) => (
          <RewardTask task={task} key={task.id} color="theme-n200d800" />
        ))}
      </Block>
    </>
  );
};

export default memo(TaskList);
