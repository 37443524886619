import React, { SVGProps } from 'react';

import classNames from 'classnames';

import { TextColorType } from '../color';
import { BaseSize, BreakpointType, MarginSizes, ResponsiveReturnType } from '../types';
import { buildResponsiveClass } from '../utils';

export type SortingIconProps = Omit<SVGProps<SVGSVGElement>, 'color' | 'stroke' | 'type' | 'fill'> & {
  color?: { upArrow: TextColorType; downArrow: TextColorType };
  /** Size of the icon. */
  size?: BaseSize | BreakpointType<BaseSize>;
  /** Margin x-axis */
  mx?: MarginSizes;
  /** Margin y-axis */
  my?: MarginSizes;
  /** Margin left */
  ml?: MarginSizes;
};

const sortingIcon = ({ color, size, mx, my, ml, onClick, className, ...rest }: SortingIconProps) => {
  let sizeClass: ResponsiveReturnType = {};
  if (size) {
    sizeClass = buildResponsiveClass(size, 'icons-');
  }

  const classes = classNames(
    'icons',
    sizeClass,
    {
      [`cursor-pointer`]: !!onClick,
      [`mx-${mx}`]: mx,
      [`my-${my}`]: my,
      [`ml-${ml}`]: ml,
    },
    className,
  );

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
      onClick={onClick}
      {...rest}
    >
      <path
        className={classNames({ [`tw-text-${color?.upArrow}`]: color })}
        d="M17 7.9403L12.0056 2.75L7.01126 7.9403H7V10.2471H16.9884V7.9403H17Z"
        fill="currentColor"
      />
      <path
        className={classNames({ [`tw-text-${color?.downArrow}`]: color })}
        d="M17 16.0597L12.0056 21.25L7.01126 16.0597H7V13.7529H16.9884V16.0597H17Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default sortingIcon;
