import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { HttpClientType, HttpErrorType } from '../../client';
import {
  AssetPairPriceParamType,
  AssetPairPriceType,
  AssetTagType,
  CryptoAssetsHistoryType,
  CryptoAssetsType,
  CryptoDepositWalletParamType,
  CryptoDepositWalletType,
  CryptoMarketItemType,
  CryptoMarketType,
  CryptoNetworkType,
  CryptoSearchAssetType,
  CryptoWithdrawalFeeParamsType,
  CryptoWithdrawalFeesType,
  CryptoWithdrawalFeeType,
  CryptoWithdrawBodyType,
  DisabledOperationType,
  QueryOptions,
  ResultType,
  TransactionsRecordType,
} from '../../model';
import { RecordsPaginationType } from '../../model/CommonTypes';
import { IdType } from '../../model/ResultType';
import { CryptoTransactionsParamsType, CryptoTransactionsType } from '../../model/Transaction';
import { CryptoApi, ICryptoApi } from '../api';
import { CryptoQueryKey, UserQueryKey } from '../constants';

class CryptoQuery {
  _api: ICryptoApi;

  public constructor(params: HttpClientType) {
    this._api = new CryptoApi(params);
  }

  public useGetDepositWallet = (params: CryptoDepositWalletParamType, options?: QueryOptions) => {
    return useQuery<CryptoDepositWalletType, CryptoDepositWalletParamType>({
      queryKey: [CryptoQueryKey.DEPOSIT_WALLET, params.network, params.asset, params],
      queryFn: () => this._api.getDepositWallet(params),
      ...options,
    });
  };

  public useGetNetworks = (options?: QueryOptions) => {
    return useQuery<CryptoNetworkType[]>({
      queryKey: [CryptoQueryKey.CRYPTO_NETWORKS],
      queryFn: () => this._api.getNetworks(),
      ...options,
    });
  };

  public usePostCryptoWithdrawal = (options?: QueryOptions) => {
    const queryClient = useQueryClient();

    return useMutation<IdType, HttpErrorType, CryptoWithdrawBodyType>({
      mutationFn: this._api.postCryptoWithdraw,
      onSuccess: () => queryClient.invalidateQueries({ queryKey: [UserQueryKey.NOTIFICATIONS] }),
      onError: (error) => {
        if (error.response?.status === 409) {
          queryClient.invalidateQueries({ queryKey: [CryptoQueryKey.CRYPTO_WITHDRAWALS_FEE] });
        }
      },
      ...options,
    });
  };
  public useDeleteWithdrawals = (options?: QueryOptions) => {
    return useMutation<ResultType, HttpErrorType, string>({ mutationFn: this._api.deleteWithdrawals, ...options });
  };

  public useGetWithdrawalFee = (params: CryptoWithdrawalFeeParamsType, options?: QueryOptions) => {
    return useQuery<CryptoWithdrawalFeeParamsType, HttpErrorType, CryptoWithdrawalFeeType>({
      queryKey: [CryptoQueryKey.CRYPTO_WITHDRAWALS_FEE, params],
      queryFn: () => this._api.getWithdrawalFee(params),
      ...options,
    });
  };

  public useGetAssets = (options?: QueryOptions) => {
    return useQuery<CryptoAssetsType[], HttpErrorType>({
      queryKey: [CryptoQueryKey.CRYPTO_ASSETS],
      queryFn: () => this._api.getAssets(),
      ...options,
    });
  };

  public useGetAssetPairPrice = (params: AssetPairPriceParamType, options?: QueryOptions) => {
    return useQuery<AssetPairPriceType, HttpErrorType>({
      queryKey: [CryptoQueryKey.ASSET_PAIR_PRICE, params],
      queryFn: () => this._api.getAssetPairPrice(params),
      ...options,
    });
  };

  /**
   * @deprecated This endpoint will be removed with next backend release, please use PriceQuery queries.
   */
  public useGetAssetHistory = (market?: string, options?: QueryOptions) => {
    return useQuery<CryptoAssetsHistoryType, HttpErrorType>({
      queryKey: [CryptoQueryKey.CRYPTO_ASSET_HISTORY, market],
      queryFn: () => this._api.getAssetHistory(market),
      ...options,
    });
  };

  public useGetSearchAsset = (text: string, options?: QueryOptions) => {
    return useQuery<RecordsPaginationType<CryptoSearchAssetType>, HttpErrorType>({
      queryKey: [CryptoQueryKey.CRYPTO_SEARCH_ASSETS, text],
      queryFn: () => this._api.getSearch(text),
      enabled: false,
      ...options,
    });
  };

  /**
   * @deprecated This endpoint will be removed with next backend release, please use PriceQuery queries.
   */
  public useGetMarket = (tags?: string, options?: QueryOptions) => {
    return useQuery<RecordsPaginationType<CryptoMarketType>, HttpErrorType>({
      queryKey: [CryptoQueryKey.CRYPTO_MARKET, tags],
      queryFn: () => this._api.getMarket(tags),
      ...options,
    });
  };

  /**
   * @deprecated This endpoint will be removed with next backend release, please use PriceQuery queries.
   */
  public useGetMarketByAsset = (assetPair?: string) => {
    return useQuery<CryptoMarketItemType, HttpErrorType>({
      queryKey: [CryptoQueryKey.CRYPTO_MARKET_BY_ASSET, assetPair],
      queryFn: () => this._api.getMarketByAsset(assetPair),
    });
  };

  public useGetDisabledOperations = (options?: QueryOptions) => {
    return useQuery<DisabledOperationType[], HttpErrorType>({
      queryKey: [CryptoQueryKey.DISABLED_OPERATIONS],
      queryFn: () => this._api.getDisabledOperations(),
      ...options,
    });
  };

  public useGetTransaction = (params: { id: string; account: string }, options?: QueryOptions) =>
    useQuery<TransactionsRecordType>({
      queryKey: [CryptoQueryKey.TRANSACTION, params.id, params.account],
      queryFn: () => this._api.getTransaction(params.id, params.account),
      ...options,
    });

  /**
   * @deprecated use PriceQuery.useGetAssetTags instead.
   * @param options
   */
  public useGetAssetTags = (options?: QueryOptions) =>
    useQuery<AssetTagType[]>({
      queryKey: [CryptoQueryKey.ASSET_TAGS],
      queryFn: () => this._api.getAssetTags(),
      ...options,
    });

  public useGetWithdrawalFees = (options?: QueryOptions) => {
    return useQuery<CryptoWithdrawalFeesType[], HttpErrorType>({
      queryKey: [CryptoQueryKey.CRYPTO_WITHDRAWAL_FEES],
      queryFn: () => this._api.getWithdrawalFees(),
      ...options,
    });
  };

  public useGetTransactions = (params: CryptoTransactionsParamsType, options?: QueryOptions) =>
    useQuery<CryptoTransactionsType>({
      queryKey: [CryptoQueryKey.TRANSACTIONS, params],
      queryFn: () => this._api.getTransactions(params),
      ...options,
    });
}

export default CryptoQuery;
