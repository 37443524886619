import { flexRender, HeaderGroup } from '@tanstack/react-table';
import classNames from 'classnames';

import SortableHeaderCell from './sortableHeaderCell';
import { TableHeaderStyle } from './types';
import { getColumnClasses, getCommonColumnStyles } from '../helpers';

interface TableHeaderProps<T> {
  headerGroups: HeaderGroup<T>[];
  headerStyle?: TableHeaderStyle;
  loading?: boolean;
}

export default function TableHeader<T>({ headerGroups, headerStyle, loading }: TableHeaderProps<T>) {
  return (
    <thead className="table-head">
      {headerGroups.map((headerGroup) => (
        <tr className="header-group" key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const { columnDef, getCanSort } = header.column;
            const headerClasses = classNames(
              'header-cell',
              `tw-font-${headerStyle?.weight || 'medium'}`,
              `tw-text-${headerStyle?.size || 'sm'}`,
              getColumnClasses(header.column),
              {
                [`px-${headerStyle?.px}`]: headerStyle?.px,
                [`py-${headerStyle?.py}`]: headerStyle?.py,
                [`pb-${headerStyle?.pb}`]: headerStyle?.pb,
                'header-background': headerStyle?.showBackground,
                'tw-text-left': !columnDef.align,
              },
            );

            const canSort = getCanSort();

            return (
              <th
                key={header.id}
                align={columnDef.align}
                className={headerClasses}
                style={{ ...getCommonColumnStyles(header.column) }}
              >
                {canSort ? (
                  <SortableHeaderCell column={header.column} loading={loading}>
                    {header.isPlaceholder ? null : flexRender(columnDef.header, header.getContext())}
                  </SortableHeaderCell>
                ) : (
                  <>{header.isPlaceholder ? null : flexRender(columnDef.header, header.getContext())}</>
                )}
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
}
