import React, { FC, useEffect } from 'react';

import {
  Alert,
  Block,
  Button,
  Col,
  Display,
  Flex,
  Form,
  Grid,
  Hidden,
  Input,
  Panel,
  Skeleton,
  Typography,
  withSuspense,
} from '@bilira-org/design';
import { extractBankCode, formatIban, isIbanFromBlacklistedBank, validateIban } from '@bilira-org/react-utils';
import { BankAccountType } from '@bilira-org/react-utils/src/model/BankAccount';
import { useTranslation } from 'react-i18next';

import { BankIcon } from '@Components/icon/BankIcon';
import TwoFAModal from '@Components/TwoFA/TwoFAModal';

import useCreateBankAccounts from './hooks/useCreateBankAccount';

interface ICreateBankAccount {
  /** Callback to be called when bank account created */
  callback: () => void;
  /**
   * Bank account to update.
   * If not provided the component will work in create mode
   */
  bankAccount?: BankAccountType;
}

/**
 * Component that handles creating or updating a bank account
 *
 * @example
 * <CreateBankAccount bankAccount={bank} /> // edit mode
 * <CreateBankAccount /> // create mode
 */
const CreateBankAccount: FC<ICreateBankAccount> = ({ bankAccount, callback }) => {
  const { t } = useTranslation();

  const initialValues = bankAccount
    ? {
        ...bankAccount,
        iban: formatIban(bankAccount.iban),
        bankCode: extractBankCode(bankAccount.iban),
      }
    : undefined;
  const {
    form,
    onSubmit,
    isPending,
    error,
    twoFAData,
    activeTwoFAModal,
    setActiveTwoFAModal,
    onVerifyTwoFA,
    twoFAType,
  } = useCreateBankAccounts({
    callback,
    initialValues,
  });
  const aliasRef = React.useRef<HTMLInputElement | null>(null);

  const empty = !bankAccount?.id;

  useEffect(() => {
    if (!empty) {
      aliasRef.current?.focus();
    }
  }, [empty]);

  return (
    <>
      <Alert status="yellow" mb="2xl">
        {t('bank-account.create-account-warning')}
      </Alert>
      <Form<BankAccountType> form={form} onFinish={onSubmit}>
        <Grid>
          <Display show={error}>
            <Col>
              <Alert status="red">{t('bank-account.create-account-error')}</Alert>
            </Col>
          </Display>
          <Col>
            <Form.Field name="id" hidden>
              <Input type="hidden" />
            </Form.Field>
            <Form.Field name="is_default" hidden>
              <Input type="hidden" />
            </Form.Field>
            <Form.Field
              hidden={!!bankAccount}
              controlled
              name="iban"
              label={t('bank-account.iban')}
              rules={{
                required: t('common.validation.required-field'),
                validate: (value) => {
                  if (!validateIban(value)) {
                    return t('common.validation.please-enter-valid-field', { 'field-name': 'IBAN' });
                  } else if (isIbanFromBlacklistedBank(value)) {
                    return t('common.validation.disallowed-bank');
                  }
                  return undefined;
                },
              }}
            >
              {({ field }) => (
                <Input
                  value={field.value}
                  onBlur={field.onBlur}
                  placeholder={t('bank-account.iban-placeholder')}
                  onChange={(e) => {
                    const iban = e.target.value;
                    if (iban) {
                      form.setValue('bank_code', extractBankCode(iban));

                      const formattedIban = formatIban(iban);
                      field.onChange(formattedIban);
                    } else {
                      field.onChange('');
                    }
                  }}
                  inputMode="numeric"
                />
              )}
            </Form.Field>
            <Hidden show={{ xs: false, sm: true }}>
              <Form.Field name="bank_code" controlled hideErrorList>
                {({ field }) => (
                  <Block row justify="end">
                    <Typography.Text mt="lg">
                      <BankIcon name={field.value} type="logo" height="xs" />
                    </Typography.Text>
                  </Block>
                )}
              </Form.Field>
            </Hidden>
          </Col>
          <Col>
            <Form.Field
              name="alias"
              label={t('bank-account.alias')}
              rules={{
                required: t('common.validation.required-field'),
                pattern: {
                  value: /^.*\S.*$/,
                  message: t('common.validation.required-field'),
                },
              }}
            >
              <Input ref={aliasRef} placeholder={t('bank-account.alias-placeholder')} />
            </Form.Field>
          </Col>
          <Col>
            <Button
              variant="filled"
              justify="center"
              type="submit"
              size="lg"
              stretch
              loading={isPending}
              disabled={!form.formState.isValid}
            >
              {t('common.save')}
            </Button>
          </Col>
        </Grid>
      </Form>
      {twoFAType && (
        <TwoFAModal
          type={twoFAType}
          twoFAData={twoFAData}
          active={activeTwoFAModal}
          setActive={setActiveTwoFAModal}
          callback={onVerifyTwoFA}
        />
      )}
    </>
  );
};

export default withSuspense(
  CreateBankAccount,
  <Panel size="md">
    <Skeleton width="size-full" height="size-8" />
  </Panel>,
);
