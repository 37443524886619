import { env } from '@Libs/constants/env';

// TODO: implement feature flag later.
class FeatureFlag {
  private readonly _featureFlagObj: any;

  constructor() {
    this._featureFlagObj = null;
  }

  getBoolean(key: string) {
    return false;
    // return getBoolean(this._featureFlagObj, this.buildKey(key));
  }

  getValue(key: string) {
    return false;
    // return getValue(this._featureFlagObj, this.buildKey(key));
  }

  private buildKey(key: string) {
    if (env.IS_PROD) {
      return `PROD_${key}`;
    }

    return `DEV_${key}`;
  }
}

const featureFlag = new FeatureFlag();

export default featureFlag;
