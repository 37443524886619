import { UserQuery } from '@bilira-org/react-utils';

import { env } from '@Libs/constants/env';

import getI18nLanguage from '../helpers/getI18nLanguage';

const client = new UserQuery({
  baseUrl: env.STABLECOIN_API_BASE_URL,
  params: { withCredentials: true },
  getLocale: getI18nLanguage,
});

Object.freeze(client);

export default client;
