import IdpQuery from '@Libs/clientInstances/idpQuery';
import { maskEmail } from '@Libs/helpers';
import Tracker from '@Libs/tracker/Tracker';

type ReturnType = {
  id: string;
  email: string;
  maskedEmail: string;
  firstName: string;
  lastName: string;
  kycLevel?: number;
  isPending: boolean;
};

/**
 * Hook for fetching user authentication information.
 *
 * @returns {ReturnType} - An object containing user authentication information.
 */
const useUserInfo = (): ReturnType => {
  const { isPending, data } = IdpQuery.useGetUserInfo({ suspense: false });

  const { id = '', email = '', first_name: firstName = '', last_name: lastName = '', kyc_level: kycLevel } = data || {};

  if (id) {
    Tracker.identify(id);
  }

  return { id, email, maskedEmail: maskEmail(email) || '', firstName, lastName, isPending, kycLevel };
};

export default useUserInfo;
