import React, { useCallback, useMemo } from 'react';

import {
  Block,
  ColumnDef,
  DataTable,
  DataTableProps,
  Hidden,
  Skeleton,
  TableBodyStyle,
  TableHeaderStyle,
} from '@bilira-org/design';
import { TableSortingState } from '@bilira-org/design/src/components/table/dataTable';
import { AssetListType, CoinSymbolType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';
import { useSessionStore } from '@/store';
import SymbolPair from '@Components/common/SymbolPair';
import H24ChangeLive from '@Components/live/H24ChangeLive';
import H24SparklineLive from '@Components/live/H24SparklineLive';
import LastPriceLive from '@Components/live/LastPriceLive';
import MarketCapLive from '@Components/live/MarketCapLive';
import useNavigateToMarket from '@Libs/hooks/useNavigateToMarket';
import AddWatchlistStar from '@Modules/market/market/table/components/components/AddWatchlistStar';

const headerStyle: TableHeaderStyle = { showBackground: true, px: 'sm', py: 'sm', weight: 'bold', size: 'xs' };
const bodyStyle: TableBodyStyle = { px: 'md', py: 'md' };

type Props = {
  quoteAsset?: CoinSymbolType;
  assetList?: AssetListType[];
  isPending: boolean;
  isFetchingNextPage?: boolean;
  sorting?: TableSortingState;
  onSortingChange?: Exclude<DataTableProps<AssetListType>['onSortingChange'], undefined | string>;
  disableSorting?: boolean;
};

const MarketTable = ({
  quoteAsset,
  assetList,
  isPending,
  isFetchingNextPage,
  sorting = [],
  onSortingChange,
  disableSorting,
}: Props) => {
  const { t } = useTranslation();
  const isLoggedIn = useSessionStore((state) => state.isLoggedIn);

  const navigate = useNavigateToMarket();

  const callback = useCallback(
    (item: AssetListType) => {
      navigate({ base: item.base_asset, quote: quoteAsset ?? item.quote_asset });
    },
    [quoteAsset],
  );

  const columns = useMemo<ColumnDef<AssetListType>[]>(
    () => [
      {
        header: '#',
        accessorKey: 'base_asset',
        id: 'star',
        align: 'left',
        size: 20,
        cell({ row: { original: item } }) {
          return <AddWatchlistStar baseAsset={item.base_asset} quoteAsset={quoteAsset || item.quote_asset} />;
        },
        skeleton: <Skeleton rounded="full" height="size-4" width="size-4" />,
        show: isLoggedIn,
        disableClick: true,
        style: () => ({ px: 'xs' }),
      },
      {
        header: t('market.table.crypto'),
        accessorKey: 'base_asset',
        id: 'crypto',
        align: 'left',
        cell({ row: { original: item } }) {
          return (
            <>
              <SymbolPair
                quote={quoteAsset ?? item.quote_asset}
                icon={item.base_asset}
                title={item.base_asset}
                description={item.base_asset_name}
                size={{
                  icon: { xs: '2xl', sm: '3xl' },
                  title: { xs: 'sm', sm: 'base' },
                  quote: { xs: 'sm', sm: 'xs' },
                  description: 'sm',
                }}
              />
            </>
          );
        },
        skeleton: (
          <Block key="crypto" row gap="sm" items="center">
            <Skeleton rounded="full" height="size-8" width="size-8" />
            <Skeleton height="size-4" width="size-24" />
          </Block>
        ),
      },
      {
        header: t('market.table.price'),
        accessorKey: 'quote_asset',
        id: 'Price',
        align: 'right',
        size: 200,

        cell({ row: { original: item } }) {
          return (
            <>
              <LastPriceLive
                align="right"
                base={item.base_asset}
                suffixColor="neutral-700"
                quote={quoteAsset ?? (item.quote_asset as CoinSymbolType)}
              />
              <Hidden show={{ xs: true, sm: false }}>
                <H24ChangeLive
                  base={item.base_asset}
                  quote={quoteAsset ?? item.quote_asset}
                  value={item.change_24}
                  justify="end"
                  size={{ xs: 'sm', sm: 'base' }}
                />
              </Hidden>
            </>
          );
        },
        skeleton: <Skeleton key="price" height="size-4" width="size-24" />,
        enableSorting: true,
      },
      {
        header: t('market.table.market-cap'),
        accessorKey: 'quote_asset',
        id: 'MarketCap',
        align: 'right',
        show: { xs: false, sm: false, md: false, lg: true },
        size: 140,
        cell({ row: { original: item } }) {
          return <MarketCapLive base={item.base_asset} quote={quoteAsset ?? item.quote_asset} />;
        },
        skeleton: <Skeleton key="market-cap" height="size-4" width="size-24" />,
        enableSorting: true,
      },
      {
        header: t('market.table.change-24h'),
        accessorKey: 'quote_asset',
        id: 'Change_24_Hour',
        show: { xs: false, sm: true },
        align: 'right',
        size: 120,
        cell({ row: { original: item } }) {
          return (
            <H24ChangeLive
              justify="end"
              base={item.base_asset}
              quote={quoteAsset ?? item.quote_asset}
              value={item.change_24}
            />
          );
        },
        skeleton: <Skeleton key="change-24h" height="size-4" width="size-16" />,
        enableSorting: true,
      },
      {
        header: '',
        accessorKey: 'quote_asset',
        id: 'sparkline',
        align: 'center',
        show: { xs: false, sm: true },
        size: 170,
        cell({ row: { original: item } }) {
          return (
            <H24SparklineLive
              base={item.base_asset}
              quote={quoteAsset ?? item.quote_asset}
              change24h={item.change_24}
            />
          );
        },
        skeleton: <Skeleton key="line" height="size-4" width="size-14" />,
      },
    ],
    [isLoggedIn, quoteAsset, t],
  );

  const rowId = useCallback<Exclude<DataTableProps<AssetListType>['rowId'], undefined | string>>(
    (item) => item.base_asset + (quoteAsset ?? item?.quote_asset),
    [quoteAsset],
  );

  return (
    <>
      <DataTable
        isFetchingNextPage={isFetchingNextPage}
        rowId={rowId}
        rounded
        headerStyle={headerStyle}
        bodyStyle={bodyStyle}
        onRowClick={callback}
        loading={isPending}
        data={assetList}
        testId="market-table"
        skeletonRowCount={5}
        columns={columns}
        emptyMessage={t('common.no-entries-found')}
        sorting={sorting}
        onSortingChange={onSortingChange}
        disableSorting={disableSorting}
      />
    </>
  );
};

export default MarketTable;
