import React, { useCallback, useState } from 'react';

import { DataTableProps, Layout, Panel } from '@bilira-org/design';
import { AssetListType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import HeadTags from '@/components/common/HeadTags';
import { StorageValues } from '@/store/localStorage';
import MarketTableContent from '@Modules/market/market/table/components/MarketTableContent';
import RankingFilterTags from '@Modules/market/market/table/components/RankingFilterTags';
import Header from '@Modules/market/ranking/Header';

import { useRankingTableControls } from './hooks/useRankingTableControls';
import { customTagsParamMapping } from '../market/table/components/hooks/constants';

const { Content } = Layout;

const Index = () => {
  const { t } = useTranslation();

  const { dataTag, setDataTag, sorting, setSorting } = useRankingTableControls();
  return (
    <>
      <HeadTags title={t('navigation.market')} titleSuffix="subpage" />
      <Header />
      <Content>
        <Panel border="neutral-400" size="2xl">
          <RankingFilterTags tag={dataTag} callback={setDataTag} />
          <MarketTableContent sorting={sorting} onSortingChange={setSorting} dataTag={dataTag} quote="TRYB" />
        </Panel>
      </Content>
    </>
  );
};

export default Index;
