import React from 'react';

import {
  Block,
  Col,
  Grid,
  Hidden,
  Icon,
  Meta,
  Separator,
  Skeleton,
  Text,
  Tooltip,
  withSuspense,
} from '@bilira-org/design';
import { concatTicker } from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';

import useFormatNumberByLivePrice from '@/libs/hooks/useFormatNumberByLivePrice';
import PriceChange from '@Components/common/PriceChange';
import useStatisticsLive from '@Libs/hooks/useStatisticsLive';
import useAssetDetail from '@Modules/market/detail/hooks/useAssetDetail';

const AssetStatisticValues = () => {
  const { t } = useTranslation();
  const { hasError, base, quote, priceData } = useAssetDetail();
  const formatNumberByLivePrice = useFormatNumberByLivePrice({ base, quote });
  const { liveData = priceData } = useStatisticsLive({ symbol: concatTicker(base, quote) });

  if (hasError) {
    return null;
  }

  const getPrice = (value?: string) => {
    return formatNumberByLivePrice({
      type: 'price',
      value: value,
      stringifyFormat: '0[.]00a',
      defaultValue: '0.00',
    });
  };

  let marketCap: any = getPrice(liveData?.m_cap);
  let volume24h: any = getPrice(liveData?.v_24);
  let circulatingSupply: any = getPrice(liveData?.circ_sup);
  let athPrice: any = getPrice(liveData?.ath);
  const change: any = BigNumber(liveData?.change_24 || '0')
    .multipliedBy(100)
    .abs()
    .toString();

  marketCap = `${marketCap.shortValue ?? marketCap.value} ${quote}`;
  volume24h = `${volume24h.shortValue ?? volume24h.value} ${quote}`;
  circulatingSupply = `${circulatingSupply.shortValue ?? circulatingSupply.value}`;
  athPrice = `${athPrice.value} ${quote}`;

  return (
    <>
      <Grid col={{ xs: 2, sm: 2, md: 2, lg: 4 }} placeItems={{ xs: 'start', md: 'start' }}>
        <Col>
          <Text color="neutral-700">{t('market.market-cap')}</Text>
          <Text color="secondary-500" weight="medium">
            {marketCap}
          </Text>
        </Col>
        <Col>
          <Meta
            gap="lg"
            extra={
              <Hidden show={{ xs: false, sm: true }}>
                <Grid style={{ width: '20px' }} placeItems="center">
                  <Col justifySelf="center">
                    <Separator height="size-16" />
                  </Col>
                </Grid>
              </Hidden>
            }
            title={
              <Block items="start">
                <Text color="neutral-700">{t('market.volume-24h')}</Text>
                <Text color="secondary-500" weight="medium">
                  {volume24h}
                </Text>
                <PriceChange value={change} />
              </Block>
            }
          />
        </Col>
        <Col>
          <Hidden show={{ xs: true, sm: true, lg: false }}>
            <div>
              <Meta
                title={<Text color="neutral-700">{t('market.circulating-supply')}</Text>}
                position="right"
                extra={
                  <Tooltip width="size-64" placement="top" message={t('market.circulating-supply-desc')}>
                    <Icon color="neutral-700" type="o:question-mark-circle" size="sm" />
                  </Tooltip>
                }
              />
              <Text color="secondary-500" weight="medium">
                {circulatingSupply}
              </Text>
            </div>
          </Hidden>
          <Hidden show={{ xs: false, sm: false, md: false, lg: true }}>
            <Meta
              gap="lg"
              extra={
                <Grid style={{ width: '20px' }} placeItems="center">
                  <Col justifySelf="center">
                    <Separator height="size-16" />
                  </Col>
                </Grid>
              }
              title={
                <div>
                  <Meta
                    title={<Text color="neutral-700">{t('market.circulating-supply')}</Text>}
                    position="right"
                    extra={
                      <Tooltip width="size-64" placement="top" message={t('market.circulating-supply-desc')}>
                        <Icon color="neutral-700" type="o:question-mark-circle" size="sm" />
                      </Tooltip>
                    }
                  />
                  <Text color="secondary-500" weight="medium">
                    {circulatingSupply}
                  </Text>
                </div>
              }
            />
          </Hidden>
        </Col>
        <Col>
          <Meta
            gap="lg"
            extra={
              <Hidden show={{ xs: false, sm: true }}>
                <Grid style={{ width: '20px' }} placeItems="center">
                  <Col justifySelf="center">
                    <Separator height="size-16" />
                  </Col>
                </Grid>
              </Hidden>
            }
            title={
              <div>
                <Text color="neutral-700">{t('market.ath')}</Text>
                <Text color="secondary-500">{athPrice}</Text>
              </div>
            }
          />
        </Col>
      </Grid>
    </>
  );
};

export default withSuspense(
  AssetStatisticValues,
  <Grid col={{ xs: 2, sm: 2, md: 2, lg: 4 }} placeItems={{ xs: 'start', md: 'start' }}>
    <Col>
      <Skeleton width="size-10" height="size-4" />
      <Skeleton width="size-12" height="size-4" mt="sm" />
    </Col>
    <Col>
      <Meta
        gap="lg"
        extra={
          <Hidden show={{ xs: false, sm: true }}>
            <Grid style={{ width: '20px' }} placeItems="center">
              <Col justifySelf="center">
                <Separator height="size-16" />
              </Col>
            </Grid>
          </Hidden>
        }
        title={
          <Block items="start">
            <Skeleton width="size-10" height="size-4" />
            <Skeleton width="size-12" height="size-4" mt="sm" />
          </Block>
        }
      />
    </Col>
    <Col>
      <Hidden show={{ xs: true, sm: true, lg: false }}>
        <div>
          <Skeleton width="size-10" height="size-4" />
          <Skeleton width="size-12" height="size-4" mt="sm" />
        </div>
      </Hidden>
      <Hidden show={{ xs: false, sm: false, md: false, lg: true }}>
        <Meta
          gap="lg"
          extra={
            <Grid style={{ width: '20px' }} placeItems="center">
              <Col justifySelf="center">
                <Separator height="size-16" />
              </Col>
            </Grid>
          }
          title={
            <div>
              <Skeleton width="size-10" height="size-4" />
              <Skeleton width="size-12" height="size-4" mt="sm" />
            </div>
          }
        />
      </Hidden>
    </Col>
    <Col>
      <Meta
        gap="lg"
        extra={
          <Hidden show={{ xs: false, sm: true }}>
            <Grid style={{ width: '20px' }} placeItems="center">
              <Col justifySelf="center">
                <Separator height="size-16" />
              </Col>
            </Grid>
          </Hidden>
        }
        title={
          <div>
            <Skeleton width="size-10" height="size-4" />
            <Skeleton width="size-12" height="size-4" mt="sm" />
          </div>
        }
      />
    </Col>
  </Grid>,
);
