import {
  ForceDecimal,
  formatPriceValue,
  RewardsTasksType,
  RewardTaskActionType,
  UserParticipationType,
} from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';
import { TFunction } from 'i18next';

import routeMap from '@/libs/route/routeMap';

import { rewardTaskActionDetailMap, rewardTaskActionTypeDetailMap } from './taskDetailMaps';

export function getTaskDetails(task: UserParticipationType | RewardsTasksType, t: TFunction, locale: string) {
  const { description, rewards, eligibility } = getTaskDescriptionAndRewards(task, t, locale);
  const href = getTaskHref(task, locale as 'tr' | 'en');

  return {
    description,
    rewards,
    eligibility,
    href,
  };
}

function getTaskDescriptionAndRewards(task: UserParticipationType | RewardsTasksType, t: TFunction, locale: string) {
  let defaultEligibility = t('rewards.detail.eligibility.true');

  if ('status' in task) {
    defaultEligibility =
      task.is_eligible === false ? t('rewards.detail.eligibility.false') : t('rewards.detail.eligibility.true');
  }

  const points = formatPriceValue({
    value: BigNumber(task.point_multiplier).times(task.action_unit_limit).toString(),
    forceDecimal: ForceDecimal.STRICT_DECIMAL,
    decimal: 2,
    locale: locale,
  });

  const {
    description = t(`rewards.detail.description-and-rules.${task.action_type}`),
    rewards = t('rewards.detail.rewards.fixed', {
      points: points,
    }),
    eligibility = defaultEligibility,
  } = rewardTaskActionDetailMap[task.action]?.(task, t, locale) ||
  rewardTaskActionTypeDetailMap[task.action_type]?.(task, t, locale) ||
  {};

  return { description, rewards, eligibility };
}

function getTaskHref(task: UserParticipationType | RewardsTasksType, locale: 'tr' | 'en'): string | undefined {
  const actionTypeToHrefMap: Partial<Record<RewardTaskActionType, string>> = {
    referral: '/referral',
    kyc1: '/login',
    kyc2: '/account/id-verification',
    kyc3: '/account/id-verification',
    crypto: '/crypto',
    fiat: '/fiat',
    fiat_or_crypto_deposit: '/fiat',
    otc: '/otc',
    crypto_deposit: '/crypto/deposit',
    crypto_withdrawal: '/crypto/withdraw',
    fiat_deposit: '/fiat/deposit',
    fiat_withdrawal: '/fiat/withdraw',
    swap: routeMap.Swap[locale],
    overnight_holding:
      task.asset === 'TRYB'
        ? routeMap.Fiat.Deposit[locale]
        : routeMap.Market.AssetPair[locale].replace(':assetPair', `${task.asset}_TRYB`),
  };

  return actionTypeToHrefMap[task.action_type] || undefined;
}
