import { useSessionStore } from '@/store';
import UserQuery from '@Libs/clientInstances/userQuery';
import Tracker from '@Libs/tracker/Tracker';

interface Props {
  /** Whether to use suspense mode while fetching data. */
  suspense?: boolean;
}

type ReturnType = {
  user: string;
  account: string;
  role: string;
  isPending: boolean;
  isSuccess: boolean;
  otcUser: boolean;
  bankVerified: boolean;
};

/**
 * Hook for fetching user authentication information.
 *
 * @param {Props} [props] - The properties for the `useAuth` hook.
 * @returns {ReturnType} - An object containing user authentication information.
 */
const useAuth = ({ suspense }: Props = {}): ReturnType => {
  const isLoggedIn = useSessionStore((state) => state.isLoggedIn);
  const { isSuccess, isPending, data } = UserQuery.useGetUsers({ suspense, enabled: isLoggedIn });
  //
  const {
    id = '',
    account = '',
    role = '',
    otc_user: otcUser = false,
    bank_verified: bankVerified = false,
  } = data || {};

  if (id) {
    Tracker.identify(id);
  }

  return { user: id, account, role, otcUser, isSuccess, isPending, bankVerified };
};

export default useAuth;
