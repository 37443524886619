import React from 'react';

import { Layout } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import HeadTags from '@Components/common/HeadTags';
import Header from '@Modules/otc/components/Header';

import PrivateOtcContent from './components/PrivateOtcContent';
import PublicOtcContent from './components/PublicOtcContent';

const { Content } = Layout;

const Index = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeadTags title={t('navigation.otc-portal')} titleSuffix="subpage" />
      <Header />
      <Content>
        <PrivateOtcContent />
        <PublicOtcContent />
      </Content>
    </>
  );
};
export default Index;
