import React from 'react';

import { Badge, Block, Button, Icon, Indicator, Popover, Separator, withSuspense } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import NotificationList from '@Components/notifications/NotificationList';
import UserQuery from '@Libs/clientInstances/userQuery';
import { withPrivateRoute } from '@Libs/hocs';
import { useSessionStore } from '@Store/sessionStore';

import ReadAllNotificationBar from './components/ReadAllNotificationBar';

/**
 * Badge for viewing notifications
 */
const NotificationBadge = () => {
  const { t } = useTranslation();
  const { data: { records: data = [], unread_count: unreadCount } = {} } = UserQuery.useGetNotifications({ limit: 5 });

  const navigate = useNavigate();

  const { isNotificationViewed, setIsNotificationViewed } = useSessionStore((state) => ({
    isNotificationViewed: state.isNotificationViewed,
    setIsNotificationViewed: state.setIsNotificationViewed,
  }));

  const hasUnreadNotifications = !isNotificationViewed && unreadCount && unreadCount > 0;
  return (
    <Indicator disabled={!hasUnreadNotifications} padding="none" item={<Badge animate size="xs" color="primary" />}>
      <Popover
        onOpen={() => {
          setIsNotificationViewed(true);
        }}
        appearance={false}
        buttonVariant="pill-neutral"
        buttonSize="md"
        icon={<Icon color="secondary-500" type="o:bell" />}
        customWidth={{ xs: '320', sm: '384' }}
        testId="notification-badge"
      >
        {(close) => (
          <Block testId="notification-dropdown">
            <ReadAllNotificationBar unreadCount={unreadCount} />
            <Separator width="size-auto" mx="md" my="sm" />

            <Block mt="xs">
              <NotificationList data={data} onClose={close} />
            </Block>
            <Button
              justify="center"
              stretch
              variant="link"
              size="md"
              mt="md"
              onClick={() => {
                navigate('/notification');
                close();
              }}
            >
              {t('navigation.notifications.see-all-notifications')}
            </Button>
          </Block>
        )}
      </Popover>
    </Indicator>
  );
};

export default withPrivateRoute(
  withSuspense(
    NotificationBadge,
    <Button variant="pill-neutral" size="md" startIcon={<Icon color="secondary-500" type="o:bell" />} />,
  ),
);
