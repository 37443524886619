import React from 'react';

import { Button, Col, Form, Grid, Input, Modal, PasswordMeter, Text } from '@bilira-org/design';
import { getErrorMessage, isPatternMatching, PasswordBody } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import idpQuery from '@Libs/clientInstances/idpQuery';
import useNotify from '@Libs/hooks/useNotify';
import { passwordSchema } from '@Modules/account/security/changePassword/helpers/passwordSchema';

type Props = {
  callback: () => void;
};
const ChangePasswordForm = ({ callback }: Props) => {
  const { mutate, isSuccess, isError, error } = idpQuery.usePatchPassword();

  const { t } = useTranslation();
  const form = Form.useForm<PasswordBody>({
    mode: 'onChange',
  });

  const { awaitNotify } = useNotify({
    isSuccess: isSuccess,
    isError: isError,
  });

  // const { twoFAData, activeTwoFAModal, setActiveTwoFAModal, handleVerifyTwoFA, handleSave } =
  //   useTwoFA('passwordChange');

  const onFinish = (data: PasswordBody) => {
    mutate(data);

    awaitNotify({
      loading: t('common.updating'),
      success: t('common.updated'),
      error: getErrorMessage(error, t('common.could-not-update')) as string,
    }).then(() => {
      callback();
    });
  };

  const onSubmit = (payload: any) => onFinish(payload);

  //  Since 2fa is not enabled on BE, we are not using this code.
  // When 2fa is enabled,this can be uncommented..
  // const onSubmit = (payload: any) => handleSave(onFinish, payload);
  // const onVerifyTwoFA = (values: string) => handleVerifyTwoFA(onFinish, values);

  return (
    <>
      <Modal.Body>
        <Form id="change-password-form" form={form} onFinish={onSubmit}>
          <Grid gap="none">
            <Col>
              <Text mb="sm">{t('account.security-settings.strengthen-password-for-security')}</Text>
            </Col>
            <Col>
              <Form.Field
                name="current_password"
                label={t('account.security-settings.current-password')}
                rules={{ required: t('common.validation.required-field-2') }}
                onChange={() => {
                  form.trigger('password');
                }}
              >
                <Input.Password placeholder={t('account.security-settings.current-password')} />
              </Form.Field>
            </Col>
            <Col>
              <Form.Field<PasswordBody>
                name="password"
                label={t('account.security-settings.password')}
                rules={{
                  required: t('common.validation.required-field-2'),
                  validate: (value, formValues) => {
                    const currentPassword = formValues.current_password;
                    if (currentPassword === value) {
                      return t('account.security-settings.new-password-should-not-match-existing');
                    }
                    const isSchemaMatching = passwordSchema.every((schema) => {
                      return isPatternMatching(value, schema.pattern);
                    });

                    return isSchemaMatching;
                  },
                }}
                onChange={() => {
                  form.trigger('password_confirmation');
                }}
              >
                <Input.Password placeholder={t('account.security-settings.password')} />
              </Form.Field>
            </Col>
            <Col>
              <Form.Field<PasswordBody>
                name="password_confirmation"
                label={t('account.security-settings.repeat-password')}
                rules={{
                  required: t('common.validation.required-field-2'),
                  validate: (value, formValues) => {
                    const password = formValues.password;
                    if (password !== value) {
                      return t('account.security-settings.passwords-not-matching');
                    }
                    return true;
                  },
                }}
              >
                <Input.Password placeholder={t('account.security-settings.repeat-password')} />
              </Form.Field>
            </Col>
            <Col>
              <PasswordMeter schema={passwordSchema} password={form.watch('password')} />
            </Col>
          </Grid>
        </Form>
      </Modal.Body>
      <Modal.Footer py="md">
        <Button
          form="change-password-form"
          mb="md"
          variant="filled"
          size="xl"
          type="submit"
          stretch
          justify="center"
          disabled={!form.formState.isValid}
        >
          {t('account.security-settings.change-password')}
        </Button>
      </Modal.Footer>
      {/*<TwoFAModal*/}
      {/*  type="passwordChange"*/}
      {/*  twoFAData={twoFAData}*/}
      {/*  active={activeTwoFAModal}*/}
      {/*  setActive={setActiveTwoFAModal}*/}
      {/*  callback={onVerifyTwoFA}*/}
      {/*/>*/}
    </>
  );
};

export default ChangePasswordForm;
