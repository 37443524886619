import React, { Suspense, useRef } from 'react';

import { Block, Button, Display, Icon, Panel, Skeleton, Typography } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import BlogQuery from '@Libs/clientInstances/blogQuery';
import AnnouncementSlider, {
  AnnouncementSliderProps,
} from '@Modules/overview/components/announcements/components/AnnouncementSlider';

const Announcement = () => {
  const { t, i18n } = useTranslation();
  const sliderRef = useRef<AnnouncementSliderProps>(null);

  const { data, isPending } = BlogQuery.useGetBlogAnnouncements(
    { locale: i18n.language },
    { gcTime: 50000, staleTime: 50000, suspense: false },
  );

  return (
    <Panel border="neutral-400" id="announcement" testId="announcement-panel">
      <Block flex>
        <Block row justify="between">
          <Typography.Text heading size="xl" weight="semibold">
            {t('overview.announcements')}
          </Typography.Text>
          <Display show={data && data.length > 1}>
            <Block row gap="sm">
              <Button
                size="md"
                variant="transparent"
                onClick={() => sliderRef.current?.prev()}
                startIcon={<Icon color="secondary-500" type="o:arrow-left" />}
              />
              <Button
                size="md"
                variant="transparent"
                onClick={() => sliderRef.current?.next()}
                startIcon={<Icon color="secondary-500" type="o:arrow-right" />}
              />
            </Block>
          </Display>
        </Block>

        {isPending ? (
          <Block row justify="between" mt="lg">
            <Block gap="sm" justify="center">
              <Skeleton width="size-44" height="size-3" />

              <Skeleton width="size-20" height="size-3" />

              <Skeleton width="size-32" mt="4xl" />
            </Block>

            <Skeleton rounded="full" width="size-20" height="size-20" mt="sm" />
          </Block>
        ) : (
          <Block>
            <AnnouncementSlider ref={sliderRef} data={data} />
          </Block>
        )}
      </Block>
    </Panel>
  );
};

export default Announcement;
