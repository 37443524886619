import { memo } from 'react';

import { Display } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import HeadTags from '@Components/common/HeadTags';
import RewardsQuery from '@Libs/clientInstances/rewardsQuery';
import { withPrivateRoute } from '@Libs/hocs';

import DefaultContent from './DefaultContent';
import ParticipatedContent from './ParticipatedContent';
import NoSeasonContent from '../components/NoSeasonContent';

const Index = () => {
  const { t } = useTranslation();

  const { data, isPending, isError } = RewardsQuery.useGetUserParticipationPrerequisites({ suspense: false });

  if (isPending) {
    return <HeadTags title={t('navigation.profile.reward')} titleSuffix="subpage" />;
  }

  return (
    <>
      <HeadTags title={t('navigation.profile.reward')} titleSuffix="subpage" />
      <Display show={isError}>
        <NoSeasonContent.Container>
          <NoSeasonContent />
        </NoSeasonContent.Container>
      </Display>

      <Display show={!isError}>{data?.participated_at ? <ParticipatedContent /> : <DefaultContent />}</Display>
    </>
  );
};

export default memo(withPrivateRoute(Index));
