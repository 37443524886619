import React, { useMemo } from 'react';

import { Icon } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { useSessionStore } from '@/store';
import NewBadge from '@Components/common/NewBadge';
import useLocaleRoute from '@Libs/hooks/useLocaleRoute';

type DataProps = {
  text: React.ReactNode;
  icon: React.ReactElement;
  activeIcon: React.ReactElement;
  href: string;
  display: boolean;
};

export function useGetNavbarData() {
  const { t, i18n } = useTranslation();
  const isLoggedIn = useSessionStore((state) => state.isLoggedIn);

  const { getRoute } = useLocaleRoute();

  const data: DataProps[] = useMemo(
    () => [
      {
        text: t('navigation.overview'),
        icon: <Icon type="o:home" size="lg" />,
        activeIcon: <Icon type="s:home" size="lg" />,
        href: '/',
        display: true,
      },
      {
        text: t('navigation.assets'),
        icon: <Icon type="o:wallet" size="lg" />,
        activeIcon: <Icon type="s:wallet" size="lg" />,
        href: '/assets',
        display: isLoggedIn,
      },
      {
        text: t('navigation.fiat'),
        icon: <Icon type="o:banknotes" size="lg" />,
        activeIcon: <Icon type="s:banknotes" size="lg" />,
        href: '/fiat',
        display: isLoggedIn,
      },
      {
        text: t('navigation.crypto'),
        icon: <Icon type="o:convert" size="lg" />,
        activeIcon: <Icon type="s:convert" size="lg" />,
        href: '/crypto',
        display: isLoggedIn,
      },
      {
        text: t('navigation.market'),
        icon: <Icon type="o:trending-up" size="lg" />,
        activeIcon: <Icon type="s:trending-up" size="lg" />,
        href: getRoute('Market'),
        display: true,
      },
      {
        text: <NewBadge badgeKey="referral">{t('navigation.profile.referral')}</NewBadge>,
        icon: <Icon type="o:user-plus" size="lg" />,
        activeIcon: <Icon type="s:user-plus" size="lg" />,
        href: '/referral',
        display: isLoggedIn,
      },
    ],
    [isLoggedIn, t, i18n],
  );

  const mobileData: DataProps[] = useMemo(
    () => [
      {
        text: t('navigation.mobile.overview'),
        icon: <Icon type="o:home" size="lg" />,
        activeIcon: <Icon type="s:home" size="lg" />,
        href: '/',
        display: true,
      },
      {
        text: t('navigation.mobile.market'),
        icon: <Icon type="o:trending-up" size="lg" />,
        activeIcon: <Icon type="s:trending-up" size="lg" />,
        href: getRoute('Market'),
        display: true,
      },
      {
        text: t('navigation.mobile.buy-sell'),
        icon: <Icon type="o:arrows-right-left" size="lg" />,
        activeIcon: <Icon type="o:arrows-right-left" size="lg" />,
        href: getRoute('Swap'),
        display: true,
      },
      {
        text: t('navigation.mobile.deposit-withdraw'),
        icon: <Icon type="o:building-library" size="lg" />,
        activeIcon: <Icon type="s:building-library" size="lg" />,
        href: '/deposit-withdraw',
        display: isLoggedIn,
        mobile: isLoggedIn,
      },
      {
        text: t('navigation.mobile.wallet'),
        icon: <Icon type="o:wallet" size="lg" />,
        activeIcon: <Icon type="s:wallet" size="lg" />,
        href: '/assets',
        display: isLoggedIn,
        mobile: isLoggedIn,
      },
    ],
    [isLoggedIn, t, i18n],
  );

  return { data, mobileData };
}
