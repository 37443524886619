import React, { useEffect, useState } from 'react';

import { Block, withSuspense } from '@bilira-org/design';
import { PriceAssetTagType } from '@bilira-org/react-utils';
import { useLocation } from 'react-router-dom';

import PriceQuery from '@Libs/clientInstances/priceQuery';
import { SWAP_COMPLETED_HASH } from '@Libs/constants';
import GeneralTags from '@Modules/market/market/table/components/components/GeneralTags';

import { CUSTOM_ASSET_TAGS } from './hooks/constants';

type Props = {
  callback: (tag?: string) => void;
  tag?: string;
};
const RankingFilterTags = ({ tag, callback }: Props) => {
  const [dataTag, setDataTag] = useState<string | undefined>(tag);

  const { data } = PriceQuery.useGetAssetTags({ suspense: false });
  const params = useLocation();

  const callbackTag = (tag?: PriceAssetTagType) => {
    setSelectedTag(tag?.tag);
  };

  useEffect(() => {
    if (params.hash && params.hash !== SWAP_COMPLETED_HASH) {
      const tag = params.hash.replace('#', '');
      setSelectedTag(tag);
    }
  }, [params]);

  const setSelectedTag = (tag?: string) => {
    setDataTag(tag);
    callback(tag);
  };

  const tags = data?.filter((d) => d.category === 'general') || [];
  const rankingTags: PriceAssetTagType[] = [
    { tag: CUSTOM_ASSET_TAGS.TOP_GAINERS, order: 1 },
    { tag: CUSTOM_ASSET_TAGS.TOP_LOSERS, order: 1 },
  ].concat(tags);

  return (
    <>
      <Block row justify="between" gap="md" items="center" mb="lg">
        <GeneralTags selectedItem={dataTag} items={rankingTags} callback={callbackTag} />
      </Block>
    </>
  );
};

export default RankingFilterTags;
