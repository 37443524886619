import { memo, ReactElement, ReactNode, useRef } from 'react';

import { ColumnDef, OnChangeFn, Row, SortingState, Table } from '@tanstack/react-table';
import classNames from 'classnames';

import TableBody from './body';
import EmptyState from './empty';
import TableHeader from './header';
import DataTableHOC from './hoc';
import { TableBodyStyle, TableHeaderStyle } from './types';
import useRoundedClasses from './useRoundedClasses';
import { Optional, XOR } from '../../../../custom';
import { BaseComponentType, BreakpointType, PaddingSizes } from '../../types';
import { generateStretchClasses, highlightFn } from '../helpers';

interface CommonProps<T> extends BaseComponentType {
  /** Data to be displayed in the table */
  data?: T[];
  /** Loading state for the table */
  loading?: boolean;
  /** Identifier for each row, can be a key or a function */
  rowId?: keyof T | ((row: T) => string);
  /** Number of skeleton rows to display while loading */
  skeletonRowCount?: number;
  /** Determines whether the table should stretch across breakpoints */
  stretch?: boolean | BreakpointType<boolean>;
  /** Padding size for the table's Y-axis */
  py?: PaddingSizes;
  /** Padding size for the table's X-axis */
  px?: PaddingSizes;
  /** Whether to show a divider between rows */
  divider?: boolean;
  /** Columns that should be pinned to the left */
  pinnedColumns?: string[];
  /** Style properties for the table header */
  headerStyle?: TableHeaderStyle;
  /** Style properties for the table body */
  bodyStyle?: TableBodyStyle;
  /** Variant of row highlighting, either 'bordered' or 'marker' */
  highlightVariant?: 'bordered' | 'marker';
  /** Key or function to determine highlighted rows */
  highlightedRowKey?: string | ((entry: T) => boolean);
  /** Message or element to display when the table is empty */
  emptyMessage?: string | ReactElement;
  /** Callback function when a row is clicked */
  onRowClick?: (data: T) => void;
  /** Function to render subcomponents for expandable rows */
  renderSubComponent?: (row: T) => ReactNode;
  /** Function to determine if a row can expand */
  getRowCanExpand?: (row: Row<T>) => boolean;
  /** Whether the cells should be rounded */
  rounded?: boolean;
  /** On endless scroll, if the next page is fetching.. */
  isFetchingNextPage?: boolean;
  /** Whether to display the header row. Defaults to `true`. */
  displayHeader?: boolean;
  /** Controlled sorting state.
   *  Note: If sorting is provided, sorting state will be in controlled mode.
   */
  sorting?: SortingState;
  /** Callback to be called when sorting state changes.
   * Note: If onSortingChange is provided, sorting state will be in controlled mode.
   */
  onSortingChange?: OnChangeFn<SortingState>;
  /** Initial sorting state. */
  initialSorting?: SortingState;
  /** Whether to use client side sorting or manual sorting by either server or external functions. Defaults to `false`.
   * Note: When clientSorting is set to false, the table will assume that the data that you provide is already sorted, and will not apply any sorting to it.
   */
  clientSorting?: boolean;
  /** Disable sorting for all columns */
  disableSorting?: boolean;
}

interface TableProvidedProps<T> {
  table: Table<T>;
}

interface ColumnsProvidedProps<T> {
  columns: ColumnDef<T, any>[];
}

export type DataTableProps<T> = XOR<TableProvidedProps<T>, ColumnsProvidedProps<T>> & CommonProps<T>;
export type DataTableBaseProps<T> = CommonProps<T> & TableProvidedProps<T>;
export type DataTableColumnProps<T> = CommonProps<T> & ColumnsProvidedProps<T>;

/**
 * Renders the base DataTable component with a pre-configured table instance.
 * */
function DataTableBase<T>({
  table,
  loading,
  stretch = true,
  px,
  py,
  divider,
  headerStyle,
  bodyStyle = { py: 'sm' },
  highlightVariant = 'bordered',
  highlightedRowKey,
  emptyMessage = 'No Entries Found',
  onRowClick,
  testId = 'table',
  renderSubComponent,
  rounded,
  isFetchingNextPage,
  skeletonRowCount,
  displayHeader = true,
}: DataTableBaseProps<T>) {
  const ref = useRef<HTMLTableElement>(null);
  const stretchClass = generateStretchClasses(stretch);
  const classes = classNames('data-table', stretchClass, {
    [`py-${py}`]: py,
    [`px-${px}`]: px,
    'table-divider': divider,
  });

  useRoundedClasses(ref, rounded, loading, table, isFetchingNextPage);

  if (table.getRowCount() === 0 && !loading) {
    return <EmptyState emptyMessage={emptyMessage} />;
  }

  return (
    <table className={classes} data-testid={testId} ref={ref}>
      {displayHeader && (
        <TableHeader headerGroups={table.getHeaderGroups()} headerStyle={headerStyle} loading={loading} />
      )}
      <TableBody
        rows={table.getRowModel().rows}
        bodyStyle={bodyStyle}
        highlightFn={(row, rowId) => highlightFn({ row, rowId, highlightedRowKey })}
        highlightVariant={highlightVariant}
        onRowClick={onRowClick}
        loading={loading}
        renderSubComponent={renderSubComponent}
        isFetchingNextPage={isFetchingNextPage}
        skeletonRowCount={skeletonRowCount}
      />
    </table>
  );
}

const DataTableBaseMemoized = memo(DataTableBase) as typeof DataTableBase;

/**
 * Renders the DataTable component, either using a provided table instance or generating one internally.
 */
function DataTable<T>({ table, ...rest }: Optional<DataTableProps<T>, 'table'>) {
  if (!table) {
    return <DataTableWithHookMemoized {...rest} />;
  } else {
    return <DataTableBaseMemoized table={table} {...rest} />;
  }
}

export default memo(DataTable) as typeof DataTable;

/**
 * Renders the DataTable component, creating a table instance internally using hooks.
 */
function DataTableWithHook<T>(props: Optional<DataTableProps<T>, 'table'>) {
  return <DataTableHOC {...props}>{(table) => <DataTableBase {...props} table={table} />}</DataTableHOC>;
}
const DataTableWithHookMemoized = memo(DataTableWithHook) as typeof DataTableWithHook;
