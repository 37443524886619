import React, { forwardRef, SVGProps } from 'react';

import classNames from 'classnames';

import { iconComponents, IconType } from './helper/icon';
import { BackgroundColorType, TextColorType } from '../color';
import { Div } from '../common';
import Spinner, { SpinnerProps } from '../spinner/spinner';
import {
  BaseComponentType,
  BaseSize,
  BreakpointType,
  FixSizeType,
  MarginSizes,
  RadiusSizes,
  ResponsiveReturnType,
} from '../types';
import { buildResponsiveClass } from '../utils';

export type IconProps = Omit<SVGProps<SVGSVGElement>, 'color' | 'stroke' | 'type' | 'fill' | 'onClick'> &
  BaseComponentType & {
    /** Color of the icon. */
    color?: TextColorType;
    /** Fill color of the icon. */
    fill?: TextColorType;
    /** Size of the icon. */
    size?: BaseSize | BreakpointType<BaseSize>;
    /** Type of the icon. */
    type: IconType;
    /** Additional class names for styling the icon*/
    className?: string;
    /** Overlay size of the icon. */
    overlaySize?: FixSizeType | BreakpointType<FixSizeType>;
    /** Overlay size of the icon. */
    overlayRounded?: RadiusSizes;
    /** Margin x-axis */
    mx?: MarginSizes;
    /** Margin y-axis */
    my?: MarginSizes;
    /** Margin left */
    ml?: MarginSizes;
    /** Loading state of the icon*/
    loading?: boolean;
    spinner?: boolean | { disabled?: boolean; size?: SpinnerProps['size']; color?: SpinnerProps['color'] };
  } & (
    | {
        /** Overlay color of the icon. */
        overlayColor: BackgroundColorType;
        /** Callback function when the icon is clicked. */
        onClick?: React.MouseEventHandler<'div'>;
      }
    | {
        overlayColor?: undefined;
        onClick?: React.MouseEventHandler<SVGSVGElement>;
      }
  );

/**
 * Icon component for rendering SVG icons with styling features.
 *
 * @example
 * // Basic usage
 * <Icon type="o:arrow-down" />
 *
 * // With overlay and click handler
 * <Icon type="o:arrow-down" overlayColor="primary-500" overlaySize="3xl" onClick={handleClick} />
 *
 */
const Icon = forwardRef<SVGProps<SVGSVGElement>, IconProps>(
  (
    {
      mx,
      my,
      ml,
      color,
      fill,
      size,
      type,
      className,
      overlayColor,
      overlaySize,
      overlayRounded = 'full',
      testId,
      loading,
      onClick,
      spinner,
      ...props
    },
    ref,
  ) => {
    let sizeClass: ResponsiveReturnType = {};
    if (size) {
      sizeClass = buildResponsiveClass(size, 'icons-');
    }

    let overlaySizeClass: ResponsiveReturnType = {};

    if (overlaySize) {
      overlaySizeClass = buildResponsiveClass(overlaySize, 'fix-size-');
    }

    const overlayClasses = classNames('icon-overlay', overlaySizeClass, {
      'rounded-full inline-flex items-center justify-center': overlayColor,
      [`overlay-bg-${overlayColor}`]: overlayColor,
      [`rounded-${overlayRounded}`]: overlayRounded,
      'overlay-loading': loading,
      [`cursor-pointer`]: !!onClick,
    });

    const classes = classNames(
      'icons',
      sizeClass,
      {
        [`cursor-pointer`]: !!onClick,
        [`tw-text-${color}`]: color,
        [`tw-fill-${fill}`]: fill,
        [`mx-${mx}`]: mx,
        [`my-${my}`]: my,
        [`ml-${ml}`]: ml,
      },
      className,
    );

    const IconComponent = iconComponents[type];
    if (!IconComponent) {
      return null;
    }

    if (overlayColor) {
      return (
        <IconWrapper spinner={spinner}>
          <Div
            className={overlayClasses}
            testId={`${testId ?? 'icon'}-overlay`}
            onClick={onClick as React.MouseEventHandler<'div'>}
            disabled={loading}
          >
            <IconComponent ref={ref} {...props} className={classes} data-testid={testId ?? 'icon'} />
          </Div>
        </IconWrapper>
      );
    }

    return (
      <IconWrapper spinner={spinner}>
        <IconComponent ref={ref} onClick={onClick} {...props} className={classes} data-testid={testId ?? 'icon'} />
      </IconWrapper>
    );
  },
);

type IconWrapperProps = Pick<IconProps, 'spinner'> & { children: React.ReactNode };

const IconWrapper = ({ spinner, children }: IconWrapperProps) => {
  if (!spinner || (typeof spinner === 'object' && spinner.disabled)) {
    return <>{children}</>;
  }

  const { size = undefined, color = undefined } = spinner === true ? {} : spinner;

  return (
    <Div className="relative">
      {children}
      <Spinner
        size={size}
        color={color}
        variant="slow-linear"
        position="absolute"
        className="top-0 left-0"
        borderSize="sm"
      />
    </Div>
  );
};

export default Icon;
