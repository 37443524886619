import { useEffect, useRef } from 'react';

import { ConfettiRef } from '@bilira-org/design';
import { useStateEffect } from '@bilira-org/react-utils';
import { useNavigate } from 'react-router-dom';

import useLegalDocuments from '@/components/layouts/components/components/hooks/useLegalDocuments';
import useAuth from '@/libs/hooks/userAuth';
import { TRACKER_EVENTS } from '@/libs/tracker/events';
import { addTrackerReward } from '@/libs/tracker/helpers/addTrackerReward';
import { handleErrorResponse } from '@/libs/utils';
import RewardsQuery from '@Libs/clientInstances/rewardsQuery';
import UserQuery from '@Libs/clientInstances/userQuery';

const useRegistrationModal = () => {
  const particleRef = useRef<ConfettiRef>(null);

  const { user } = useAuth();

  const navigate = useNavigate();

  const { data, isPending: isPrerequisitesPending } = RewardsQuery.useGetUserParticipationPrerequisites({
    suspense: false,
  });
  const { mutateAsync: mutateParticipation, isPending: isParticipationPending } =
    RewardsQuery.usePatchUserParticipation();
  const { mutateAsync: mutateDisclaimerRecord, isPending: isDisclaimerPending } = UserQuery.usePostDisclaimerRecords();

  const [openState, setOpen] = useStateEffect(
    (data?.can_participate && !data.participated_at && data.is_pop_up_allowed) || false,
    [data],
  );

  const { isCookieConsent, isLegalDocumentModalOpen } = useLegalDocuments();

  // Check if the consents/legal documents modal is open,
  // so that this modal can be shown after saving consents or legal documents preferences.
  const open = !isCookieConsent && !isLegalDocumentModalOpen && openState;

  const handleParticipation = () => {
    mutateParticipation()
      .then(() => {
        setOpen(false);
        navigate('/rewards');
      })
      .catch((e) => handleErrorResponse(e));
  };

  const handleReject = () => {
    mutateDisclaimerRecord({ user, type: 'point_system_join_announcement', entity_id: data?.season })
      .then(() => {
        setOpen(false);
      })
      .catch((e) => handleErrorResponse(e));
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => particleRef.current?.boom(), 100);
      addTrackerReward(TRACKER_EVENTS.REWARDS.REGISTRATION_MODAL_SHOWN);
    }
  }, [open]);

  return {
    open,
    setOpen,
    handleReject,
    isRejectPending: isDisclaimerPending,
    handleParticipation,
    isParticipationPending,
    isPrerequisitesPending,
    particleRef,
  };
};

export default useRegistrationModal;
