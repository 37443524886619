import React from 'react';

import featureFlag from '@Libs/tracker/FeatureFlag';

export type FeatureFlagProps = {
  /* Content to display. */
  children: React.ReactNode;
  /* Whether to display the content. */
  rcKey: string;
};

/**
 * Display component based on Remote Config parameters.
 *
 * @example
 * <DisplayFeatureFlag rcKey={'REMOTE_CONFIG_KEY'}>
 *   <div>Content</div>
 * </DisplayFeatureFlag>
 */

const DisplayFeatureFlag = ({ children, rcKey }: FeatureFlagProps) => {
  const show = featureFlag.getBoolean(rcKey);

  if (!show) {
    return null;
  }

  return <>{children}</>;
};

export default DisplayFeatureFlag;
