import React, { useState } from 'react';

import { Button, Flex, Icon, Panel, Text, Tooltip } from '@bilira-org/design';
import { formatIfEmpty } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import DisplayFeatureFlag from '@Components/common/DisplayFeatureFlag';
import FormattedNumber from '@Components/common/FormattedNumber';
import FormattedPercentage from '@Components/common/FormattedPercentage';

import ReferralHistoryModal from './ReferralHistoryModal';

type Props = {
  defaultCommissionPercentage?: number;
  totalReferralCount?: number;
  totalEarning?: string;
};

const ReferralAccountInfo = ({ defaultCommissionPercentage = 0, totalReferralCount, totalEarning }: Props) => {
  const { t } = useTranslation();
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);

  return (
    <>
      <Panel color="theme-n200d800" size="4xl">
        <Text heading size="xl" weight="bold" mb="lg">
          {t('referral.referral-account')}
        </Text>

        <Flex direction={{ xs: 'col', sm: 'row' }} justify="between" gap="xl">
          <DisplayFeatureFlag rcKey="REFERRAL_EARN">
            <Flex.Item grow="1">
              <Text size="sm">{t('referral.base-commission-rate')}</Text>
              <Text size="2xl" color="secondary-500">
                <FormattedPercentage value={defaultCommissionPercentage} formatPriceProps={{ defaultValue: '-' }} />
              </Text>
            </Flex.Item>
            <Flex.Item grow="1">
              <Text size="sm">{t('referral.earnings')}</Text>
              <Flex direction="row" items="center" gap="xs">
                <FormattedNumber
                  size="2xl"
                  color="secondary-500"
                  value={totalEarning}
                  formatPriceProps={{ defaultValue: '0' }}
                  suffix="TRYB"
                />

                <Tooltip width="size-56" placement="top" message={t('referral.earned-commissions-credited-daily')}>
                  <Icon color="neutral-600" type="o:information-circle" size="sm" />
                </Tooltip>
              </Flex>

              <Button size="sm" variant="outline" mt="md" onClick={() => setIsHistoryModalOpen(true)}>
                {t('referral.referral-earning-history')}
              </Button>
            </Flex.Item>
          </DisplayFeatureFlag>
          <Flex.Item grow="1">
            <Text size="sm">{t('referral.total-referred')}</Text>
            <Text size="2xl" color="secondary-500">
              {formatIfEmpty(totalReferralCount)}
            </Text>
          </Flex.Item>
        </Flex>
      </Panel>
      <ReferralHistoryModal open={isHistoryModalOpen} onClose={() => setIsHistoryModalOpen(false)} />
    </>
  );
};

export default ReferralAccountInfo;
